//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

*[dir="rtl"] { 
    // RTL mode
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app-rtl.scss";

 }
#book, #poem{
  width: 18vw;
}

#category{
  width: 10vw;
}

body[data-sidebar-size="small"] .main-content {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb{
  background: #1A5D1A;
}


.accordian-bg-products {
    background-color: $gray-300;
}

.nav-link {
    cursor: pointer;
}

.text-green{
  color: $green !important;
}

.dataTables_filter, .dataTables_paginate
{
    float: right;
    .pagination {
            .page-link {
                border-radius: 30px !important;
                margin: 0 3px;
                border: none;
                text-align: center;
                cursor: pointer;
            }
    }
}

.rdw-editor-main
{
    border: 1px solid  $gray-300;
    height: 239px;
}
.dz-message
{
     text-align: center;
    padding: 100px;
}

.react-datepicker-wrapper
{
    width: 100%!important;
}

.ReactModal__Overlay
{
  z-index: 1001!important;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.react-bootstrap-table{
    padding-top: 0px!important;
}

.square-switch
{
 .react-switch-bg
 {
 border-radius : 5px!important;
 }   
}

input[type="range"] {
    -webkit-appearance: auto!important;
}

.chat-conversation-height {
        min-height: 500px;
}

.expand-cell{
    cursor: pointer!important;
}
.book-image-in-table{
    width: 30px;
    height: 30px;
    border-radius: 100%;
}
.update-book-image{
    width: 100px;
    height: 100px;
    border-radius: 30px;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 6px solid $success;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  .modal-header{
    justify-content: center !important;
    .modal-title{
      font-size: $big-font-size !important;
    }
  }
  .detail-edit-btn{
    margin-top: -50px !important;
  }
  .login-logo{
    width: 200px;
    height: 200px;
  }
  .keywords{

    .keyword{
      width: fit-content;
    padding: 5px 10px;
    border-radius: 0.25rem;
    margin: 10px;
    border: 1px solid #ced4da;
    }
  }
  $circleSize: 165px;
  $radius: 100px;
  
  .profile-pic {
    color: transparent;
    transition: all .3s ease;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: all .3s ease;
    
    input {
      display: none;
    }
    
    img {
      position: absolute;
      object-fit: cover;
      width: $circleSize;
      height: $circleSize;
      box-shadow: 0 0 10px 0 rgba(255,255,255,.35);
      border-radius: $radius;
      z-index: 0;
    }
    
    .-label {
      cursor: pointer;
      height: $circleSize;
      width: $circleSize;
    }
    
    &:hover {
      .-label {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.8);
        z-index: 10000;
        color: rgb(250,250,250);
        transition: background-color .2s ease-in-out;
        border-radius: $radius;
        margin-bottom: 0;
      }
    }
    
    span {
      display: inline-flex;
      padding: .2em;
      height: 2em;
    }
  }
  // mcq styling
  .test {
    > .module {
      &:before {
        content: 'Module:';
        font-weight: bold;
        margin-right: 8px;
      }
    }
    > .level {
      &:before {
        content: 'Level:';
        font-weight: bold;
        margin-right: 8px;
      }
    }
    .question {
      .choices {
        width: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
        
        .choice {
          position: relative;
          min-width: 300px;
  
          input {
            position: absolute;
            clip: rect(0, 0, 0, 0);
          }
          
          label {
            font-weight: normal;
            cursor: pointer;
            display: block;
            padding: 9px;
            border-radius: 5px;
            background: #ddd;
            

            &:hover {
              background: $green;
              color: #fff;
            }
            
            .glyphicon {
              margin-right: 8px;
              color: #888;
            }
          }
          *[dir="rtl"] label{
            text-align: right !important;
          }
          input:checked + label {
            background: $green;   
            color: #fff;
            .glyphicon {
              color: #fff;
            }
          }
        }
      } //choices
    } // question
  
  } // test
  .show-poem-field{
    font-size:24px !important;
    text-align: center;
  }
  .book-poems-list{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    li{
      padding: 10px 0px;
      display : flex;
      justify-content: flex-start;
      align-items: center;
      i{
        color: $green;
      }
      a{
        color: $black;
        &:hover{
          color: $green;
        }
      }
    }
  }

  // vertical tabs
  .vertical-tab{
    padding-left: 10px;
    .nav-pills{
      width: auto;
      min-width: 35%;
      border: none;
      box-shadow: -6px 0 0 rgba(0,0,0,0.1);
      vertical-align: top; 
        a{
          color: #444;
          background-color: #ddd !important;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.5px;
          text-transform: capitalize;
          text-align: center;
          padding: 10px 15px;
          margin: 0 0 20px -10px;
          border-radius: 0 20px 20px 0 !important;
          border: none;
          position: relative;
          z-index: 1;
          transition: all 0.4s ease 0s;
          &:hover{
            color: #fff;
            background-color: $green;
            border: none;
            text-shadow: 3px 3px rgba(0,0,0,0.4);
          }
          &::before{
            content: '';
            background: linear-gradient(to top right,transparent 50%,#333 50%);
            height: 10px;
            width: 10px;
            position: absolute;
            left: 0;
            top: 100%;
            z-index: -1;
          }
      }
        a.active{
          color: #fff;
          background-color: $green !important;
          border: none;
          text-shadow: 3px 3px rgba(0,0,0,0.4);
          &:hover{
            color: #fff;
            background-color: $green;
            border: none;
            text-shadow: 3px 3px rgba(0,0,0,0.4);
          }
          &::before{
            content: '';
            background: linear-gradient(to top right,transparent 50%,$green 50%);
          }
        }
  }
  .tab-content{
    padding: 0px 20px;
}
}

@media only screen and (max-width: 479px){
    .vertical-tab .nav-tabs{
        width: 100%;
        display: block;
    }
    .vertical-tab .nav-tabs li a{
        padding: 10px 10px 9px;
        margin: 0 0 12px -10px;
    }
    .vertical-tab .tab-content{
        font-size: 14px;
        display: block; 
    }
}
.pagination{
  .page-link{
    .visually-hidden{
      display: none;
    }
  }
}
.keywords-list{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  li{
    padding: 0.47rem 0.75rem;
    border-bottom: 1px solid $green;
  }
  [dir="rtl"] li{
    text-align: right;
  }
}
.lang {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .input-radio {
    box-shadow: 0px 0px 0px 1px #6d6d6d;
    font-size: 3em;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 4px solid #fff;
    background-clip: border-box;
    border-radius: 50%;
    appearance: none;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  .input-radio:checked {
    box-shadow: 0px 0px 0px 4px $green;
    background-color: $green;
  }
  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 0px 15px;
    .text{
      margin-left: 10px;
      margin-right: 10px;
      font-size: $big-font-size;
    }
  }
}
.video-container {
  position: relative;
  iframe {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  &:hover .overlay{
    opacity: 1;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(29, 93, 61, 0.67);
    .tag-text {
      color: $white;
      font-size: 15px;
      position: absolute;
      top: 20px;
      left: 20px;
      text-align: center;
    }
    .edit-icons{
      color: $white;
      position: absolute;
      bottom: 20px;
      left:20px;
      .edit{
        position: absolute;
        background: $darkyellow;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      bottom: 0px;
      left:20px;
      }
      .delete{
        position: absolute;
        background: $darkyellow;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      bottom: 0px;
      right:-280px;
      }
    }
  }
}
.video-detail-container {
  position: relative;
  iframe {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  &:hover .overlay{
    opacity: 1;
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(29, 93, 61, 0.67);
    .tag-text {
      color: $white;
      font-size: 15px;
      position: absolute;
      top: 20px;
      left: 20px;
      text-align: center;
    }
    .edit-icons{
      color: $white;
      position: absolute;
      bottom: 20px;
      left:20px;
      .edit{
        position: absolute;
        background: $darkyellow;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      bottom: 0px;
      left:20px;
      }
      .delete{
        position: absolute;
        background: $darkyellow;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      bottom: 0px;
      right:-210px;
      }
    }
  }
}
// banner page
figure.snip1205 {
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  background: #000000;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
figure.snip1205 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
figure.snip1205 img {
  max-width: 100%;
  vertical-align: top;
}

figure.snip1205 .icons-row {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);

}
figure.snip1205 i {
  border-radius: 50%;
  font-size: 34px;
  color: #000000;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
figure.snip1205 i.edit {
 float: right;
}
figure.snip1205 i.edit {
 float: left;
}
figure.snip1205 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
figure.snip1205.blue {
  background-color: #2472a4;
}
figure.snip1205.blue i {
  color: #20638f;
}
figure.snip1205.red {
  background-color: #ab3326;
}
figure.snip1205.red i {
  color: #962d22;
}
figure.snip1205.yellow {
  background-color: #e08e0b;
}
figure.snip1205.yellow i {
  color: #c87f0a;
}
figure.snip1205.green {
  background-color: #229955;
}
figure.snip1205.green i {
  color: #1e8449;
}
figure.snip1205.orange {
  background-color: #d67118;
}
figure.snip1205.orange i {
  color: #bf6516;
}
figure.snip1205.navy {
  background-color: #2b3c4e;
}
figure.snip1205.navy i {
  color: #222f3d;
}
figure.snip1205:hover img,
figure.snip1205.hover img {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
figure.snip1205:hover .icons-row,
figure.snip1205.hover .icons-row {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  transition: all 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
