// 
// general-rtl.scss
//

html {
    direction: rtl;
}

body {
    text-align: right;
    font-family: $font-family-urdu !important;
}